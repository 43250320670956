import React, { useEffect } from 'react';
import Wp from '../../images/whatsapp.png';
import up from '../../images/up arrow.png';
import './Whats.css';

const Whats = () => {
  useEffect(() => {
    const handleScroll = () => {
      const mainUp = document.querySelector('.main-up');
      if (window.scrollY > window.innerHeight) {
        mainUp.classList.add('visible');
      } else {
        mainUp.classList.remove('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='wp-container'>
      <div>
        <a href="https://wa.me/919443711608?text=Hello">
          <img className='wp-icon' src={Wp} alt="WhatsApp Icon" />
        </a>
      </div>
      <div className='main-up'>
        <a href='#Top' className='link'>
          <div className='up'>
            <span className='span-up'>S</span>
            <span className='span-up'>C</span>
            <span className='span-up'>R</span>
            <span className='span-up'>O</span>
            <span className='span-up'>O</span>
            <span className='span-up'>L</span>
            <span className='span-up mt-2'>U</span>
            <span className='span-up'>P</span>
            <img className='up-arrow mt-2' src={up} alt="Up Arrow" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Whats;
