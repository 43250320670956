import React from 'react'
import Founder from '../../images/founder.png'
import About1 from '../../images/about_img.png'
import melam from '../../images/melam.svg'
import img1 from "../../images/rr_logo.png";
import './About.css'



const About = () => {
  return (
    <div id="main-content">
         
        <div className='container-fluid about-container'>
            <div className='row mt-5'>
                <div className='col'>
                    <div className="row head" data-aos="fade-up" data-aos-duration="1800">
                        <h1>Our Story</h1>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-sm-10 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="1800">
                            <img className='Ab-img' src={About1}/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-left" data-aos-duration="1800">
                            <p className='para'>Every love story deserves a grand beginning, and for years, RR Thoranam Mahal, the best wedding hall in Coimbatore, has been the canvas for countless unforgettable ones. We are dedicated to offering exquisite venues that seamlessly blend tradition with modern elegance. With a steadfast focus on exceeding expectations and celebrating your unique vision, we deliver impeccable service and create unforgettable memories. While weddings are our passion, our versatile spaces seamlessly transform to host any special occasion, creating a sophisticated yet inviting atmosphere that leaves a lasting impression. Whether you envision a business conference, a heartwarming family gathering, or any other event you desire, our dedicated team collaborates with you to ensure every detail reflects your unique vision.

</p>
                        </div>
                    </div>  
                </div>
            </div>
            {/* section2 */}
            <div className='row section2'>
                <div className='col  '>
                    <div className="row found-box">
                        <div data-aos="fade-up" data-aos-duration="1500"><h1>Founder</h1></div>  
                        <div className="mel" data-aos="fade-down-left" data-aos-duration="1500" ><img className='melam' src={melam}/></div>
                    </div>
                    <div className="row  sub-sec2  ">
                       <div className="col-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3" data-aos="zoom-in" data-aos-duration="1500">
                        <img className='Founder mb mb-xxl-4' src={Founder}/>
                       </div>
                       <div className="col col-sm-12 col-lg-8 " data-aos="fade-left" data-aos-duration="1500">
                        <p className='mt-3 mt-sm-0 mt-md-0 mt-lg-5 para'>Mr. Karana Boopathy, a Civil Engineer, has over 30 years of experience as a builder and land promoter. Since the 1990s, he has led projects across Tamil Nadu, including telephone exchanges and cellular towers. He is known for his innovative design and customer-centric approach, he has served as District Governor of Lions Club Coimbatore Chapter 324 B1 and Chairman of the Builders Association of India, Coimbatore. His work has earned him numerous awards for technical excellence and community service.
                        He also holds several esteemed positions, including member of Arangavalar – HRNC Dept for Coimbatore Revenue District, Chairman of Lions District 324 B1 Blood Bank, and member of the Indian Chamber of Commerce, Cosmopolitan Club, and Jenney’s Club. Additionally, he is the Vice President of the Coimbatore District Cycling Association. His dedication has been honored with awards like the International President's Certificate of Appreciation from Lions Club International and the "Excellence and Extraordinary Execution of Residential Space" award from the Governor of Tamil Nadu.
                        </p>
                       </div>
                       
                    </div>  
                </div>
            </div>

            <div className="row  section-4" id="cm">
          <div className="col-12 col-sm-4">
            <h1 className="sub-head-ab" data-aos="fade-up" data-aos-duration="1500">Book Us Now</h1>
            <div className="flex-1 gap-4 mt-4">
              <input className="" placeholder="Name" itemType="text" data-aos="fade-right" data-aos-duration="1500" />
              <input className=" " placeholder="Number" itemType="text" data-aos="fade-right" data-aos-duration="1500" />
              <input className=" pb-5" placeholder="message" itemType="text" data-aos="fade-right" data-aos-duration="1500" />
              <button class="btn" data-aos="fade-up" data-aos-duration="1500"><i class="animation"></i>SEND<i class="animation"></i>
              </button>
              <div>

              </div>
            </div>

          </div>
          <div className="col-2"><div className="line" data-aos="fade-in" data-aos-duration="2000">.</div></div>
          <div className="col-12 col-sm-6" data-aos="fade-left" data-aos-duration="1500">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.455413578012!2d76.86744217402027!3d11.004417454949072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85ffe65bd978d%3A0x7672f8ac9242ddcc!2sRR%20Thoranam%20Mahal%20Best%20Wedding%20Halls%20in%20Coimbatore%20%7C%20Ac%20Marriage%20Halls%20%7C%20Kalyana%20Mandapam!5e0!3m2!1sen!2sin!4v1718863245032!5m2!1sen!2sin" width="550" height="450" allowfullscreen="" loading="lazy" className="map" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        </div>
    </div>
  )
}

export default About