import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";  // Correct import for Autoplay
import test1 from "../../images/test1.jpg";
import test2 from "../../images/test2.png";
import test3 from "../../images/test-3.png";
import test4 from "../../images/test4.png";
import test5 from "../../images/test5.png";
import test6 from "../../images/test6.png";
import star from "../../images/star.png";
import "swiper/css";  // Import Swiper styles


import "./Testi.css";

export default function Testi() {
  return (
    <>
      <div className="row test-cont">
        <div className="col-12 col-sm-4 col1-test" data-aos="zoom-out-right">
          <h1 className="testimonial-head">Testimonial</h1>
          <p className="p-1">We combine excellence and luxury to create a magical<br/> experience for you and your guests. </p>
          <p className="p-2">We combine excellence and luxury to create a magical<br/> experience for you and your guests.</p>
        </div>
        <div className="col-12 col2-test col-sm-8">
          <Swiper
            className="mySwiper"
            slidesPerView={1}
            loop={Infinity}
            modules={[Autoplay]}  // Add Autoplay module here
            autoplay={{ 
              delay: 3000,  // Set delay in milliseconds (3000ms = 3s)
              disableOnInteraction: false,  // Allows autoplay to continue after user interactions
            }}
         
              breakpoints={{  // Define breakpoints for responsive behavior
               
                640: {
                  slidesPerView: 2,
                },
                
               
                
              }}
          >
            <SwiperSlide>
              <div className="card" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                <div className="testi-head"><img className="card-img" src={test1} alt="Testimonial" /><h5>Girinath kumar</h5></div>
                <div>
                  <div className="star-flex">
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                  </div>
                  <p className="test-para">Best hall for wedding and reception with spacious dinning hall attached rooms, good maintenance,and car parking,</p>
                  
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="3000">
              <div className="testi-head"><img className="card-img" src={test2} alt="Testimonial" /><h5>Guruvel</h5></div>
              <div>
                <div className="star-flex">
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                  </div>
                  <p className="test-para">Very spacious hall, ample car parking. It has 3 storey of halls seating capacity of 300-500 and dining for 200. </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
              <div className="testi-head"><img className="card-img" src={test3} alt="Testimonial" /><h5> Manikandan</h5></div>
                <div>
                <div className="star-flex">
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                  </div>
                  <p className="test-para">Wow place for grand wedding but it's too far from main town.All floors have bathroom facilities and lifts on both sides. Great</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card" data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
              <div className="testi-head"><img className="card-img" src={test4} alt="Testimonial" /><h5>Suryan Iyer</h5></div>
                <div>
                <div className="star-flex">
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                  </div>
                  <p className="test-para">The convention hall is of decent size, multiple floors. Mandapam, amenities and rooms were very good. Clean and friendly </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
              <div className="testi-head"><img className="card-img" src={test5} alt="Testimonial" /><h5>Senthil Kumar KS</h5></div>
                <div>
                <div className="star-flex">
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                  </div>
                  <p className="test-para">One of the best marriage halls in Coimbatore. But the location is 13 Km away from the city, on Thondamuthur main road.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
              <div className="testi-head"><img className="card-img" src={test6} alt="Testimonial" /><h5>anantha hari</h5></div>
                <div>
                <div className="star-flex">
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                    <img className="star" src={star} alt="Star" />
                  </div>
                  <p className="test-para">All facilities are good and adequate for hosting over 500 guests comfortably without feeling crowded for space</p>
                </div>
              </div>
            </SwiperSlide>
            
          </Swiper>
        </div>
      </div>
    </>
  );
}
