import React from 'react';
import './booknow.css';

const SendButton = () => {
  const mobileNumber = "04224355222"; 

  return (
    <a href={`tel:${mobileNumber}`} className="send-button-link">
      <button className="send-button">
        <div className="svg-wrapper-1">
          <div className="svg-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                fill="currentColor"
                d="M6.62 10.79a15.91 15.91 0 006.59 6.59l2.2-2.2a1 1 0 011.13-.21 11.72 11.72 0 004.54 1.1 1 1 0 011 1v3.5a1 1 0 01-1 1A18 18 0 012 4.5a1 1 0 011-1h3.5a1 1 0 011 1 11.72 11.72 0 001.1 4.54 1 1 0 01-.21 1.13l-2.2 2.2z"
              ></path>
            </svg>
          </div>
        </div>
        <span>Book Now</span>
      </button>
    </a>
  );
};

export default SendButton;
