import React from 'react'
import logo from '../../images/rr_logo.png'
import "./Fotter.css";
import f1 from '../../images/location.png'
import f2 from '../../images/call.png'
import soc1 from '../../images/insta.png' 
import soc2 from '../../images/fb.png' 
import soc3 from '../../images/x.png' 
const Footer = () => {
    return (
        <div className='fotter'>
            <div className='row bdy ' >
                <div className='col pt-2 pt-sm-5 bd2 container-fluid ' data-aos="fade-up" data-aos-duration="1500">
                    <div className='row'><img className='flogo' src={logo} /></div>
                    <div className='row mt-4'>
                        <div className='col-12 col-sm-5 x'><p className='fot-p'>At RR Thoranam Mahal, we are dedicated to providing exceptional service and transforming your special event into an unforgettable memory. Stay connected with us </p>
                        </div>
                        <div className='col-2 hid'></div>
                        <div className='col-12 col-sm-5'>
                            <div className='row '>
                                <div className='add'>
                                    <div className='flex2'>
                                        <img className='ficon' src={f1} /><p className='fot-p'>122, Kalikanaiken palayam, Near RR Sai Abhinav Apartment, Thondamuthur Rd, Coimbatore, Tamil Nadu 641007</p>
                                    </div>
                                    <div className='flex2'>
                                        <img className='ficon mp ' src={f2} /><p className='fot-p'>0422 435 5222 | +91 9443 711 608</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='soc'>
                     <a href='https://www.instagram.com/rr_thoranammahal?igsh=NjJwdms0YXR3b3ps'><img className='sicon  mb-4 ' src={soc1}/></a>
                        {/* <img className='sicon mb-4' src={soc2}/>
                        <img className='sicon mb-4' src={soc3}/> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer