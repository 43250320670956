import React, { useState, useEffect, useRef } from "react";
import "./Menu.css";
import logo from "../../images/rr_logo.png";
import med1 from '../../images/int.png';
import med2 from '../../images/tw.png';
import med3 from '../../images/face.png';
import { Link } from "react-router-dom";
import mega from '../../images/megaphone.png'
import SendButton from "../booknow/booknow";

const SideNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidenavRef = useRef(null);

  const toggleSidenav = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
      document.addEventListener('mousedown', handleClickOutside);
      const mainContent = document.getElementById("main-content");
      if (mainContent) {
        mainContent.classList.add("blurred");
      }
    } else {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
      const mainContent = document.getElementById("main-content");
      if (mainContent) {
        mainContent.classList.remove("blurred");
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="container-fluid seperator " id='Top'>
      <div>
        <Link to="./"><img className="logo" src={logo} alt="Logo" /></Link>
      </div>

      <h6 className="top-number">&#9742; 0422 435 5222 | +91 9443 711 608</h6>

      <div className="buttons">
      <div className="normal-nav">
        <Link to='/' className="nav-link">Home</Link>
        <Link to='/About' className="nav-link">About</Link>
        <Link to='/Facilities' className="nav-link">Facilities</Link>
        <Link to='/Gallery' className="nav-link">Gallery</Link>
      </div>
        <div className="booknow">
          
          <SendButton/>
        </div>
        <div
          className={`toggle-button ${isOpen ? 'hide' : ''}`}
          onClick={toggleSidenav}
        >
          ☰
        </div>
        <div className={`sidenav ${isOpen ? "sidenav-open" : ""}`} ref={sidenavRef}>
          <a href="#" className="close-btn" onClick={toggleSidenav}>
            &times;
          </a>
          <div className={`controls ${isOpen ? 'controls-open' : 'controls-close'}`}>
            <Link to='/' onClick={toggleSidenav}>Home</Link>
            <Link to='/About' onClick={toggleSidenav}>About</Link>
            <Link to='/Facilities' onClick={toggleSidenav}>Facilities</Link>
            <Link to='/Gallery' onClick={toggleSidenav}>Gallery</Link>
          </div>
          <div className={`icon-nav ${isOpen ? 'icon-nav-open' : 'icon-nav-close'}`}>
            <img className="med" src={med1} alt="Icon 1" />
            <img className="med" src={med2} alt="Icon 2" />
            <img className="med" src={med3} alt="Icon 3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
