import React from 'react'
import './Hall.css'
import kurtholaihall from '../../images/mavilaihall.JPG'
import mavilaihall from '../../images/rr-22.webp'
import wedding from '../../images/weddinghal.png'
import { Link } from 'react-router-dom'


export const Hall = () => {
  return (
    <div className='container-fluid hall-main p-0 '>
        <div className="main-container1 ">
            <div className="allimg-contain">
                
                    <h1 className='our-wedding' data-aos="fade-in"
                              data-aos-duration="3000">Our Wedding Hall's</h1>
                
                <div className="row hall-container mt-4 mt-sm-5">
                    <div className="col-12 col-sm-12 col-md-6 order-2 order-sm-2 order-md-1 kurutholai-content" data-aos="fade-right" data-aos-duration="2000">
                        <h1 className='Kurutholai-head'>Wedding Hall</h1>
                        <p className='Kurutholai-para'>Celebrate your big day at RR Thoranam Mahal, Coimbatore’s best wedding hall. Our grand hall, spanning an impressive 84 * 66 sq ft, is designed to accommodate 1,000 to 4,000 guests, making it the perfect choice for both intimate gatherings and large-scale celebrations. Our hall offers a luxurious setting that exudes elegance, ensuring a memorable backdrop for your special day. Our venue is fully air-conditioned to keep everyone comfortable, regardless of the season.  </p>
                        <Link className='read-more-button' to="./Gallery">
                        <span className='lines'></span>
                        SEE MORE</Link>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 order-1 order-sm-1 order-md-2 img-container"data-aos="fade-left" data-aos-duration="2000">
                        <img src={wedding} alt="./Gallery" />
                    </div>

                </div>
                <div className="row hall-container mt-5">
                    <div className="col-12 col-sm-12 col-md-6 order-sm-2 order-md-1 img-container" data-aos="fade-right" data-aos-duration="2000">
                        <img src={mavilaihall} alt="./Gallery" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 order-sm-1 order-md-2 kurutholai-content" data-aos="fade-left" data-aos-duration="2000">
                        <h1 className='Kurutholai-head'>Maavilai Hall</h1>
                        <p className='Kurutholai-para'>Maavilai Hall within the esteemed RR Thoranam Mahal, offers a unique and charming space for your traditional leaf dining experience. This dedicated dining space caters specifically to the authentic tradition of leaf dining, providing a warm and inviting atmosphere for gatherings of up to 150 guests. With a spacious area of 36*78 square feet, Maavilai Hall provides ample room for comfortable seating and a pleasant dining experience. We uphold the highest standards of cleanliness and hygiene to ensure a safe dining environment for all our guests.
                        </p>
                        <Link className='read-more-button' to="./Gallery">
                        <span className='lines'></span>
                        SEE MORE</Link>
                    </div>
                    

                </div>
                <div className="row hall-container mt-5">
                    <div className="col-12 col-sm-12 col-md-6 order-2 order-sm-2 order-md-1 kurutholai-content" data-aos="fade-right" data-aos-duration="2000">
                        <h1 className='Kurutholai-head'>Kurutholai Hall</h1>
                        <p className='Kurutholai-para'>Kurutholai Hall at RR Thoranam Mahal offers a versatile buffet hall designed to accommodate a relaxed and social dining experience. With dimensions of 36*78 sq ft, it is perfectly sized for floating crowds of up to 100 guests, making it an ideal space for smaller wedding functions, cocktail receptions, or post-wedding brunches. The hall's flexible layout allows guests to move around and socialize effortlessly, creating a vibrant and dynamic atmosphere that enhances any event.</p>
                        <Link className='read-more-button' to="./Gallery">
                        <span className='lines'></span>
                        SEE MORE</Link>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 order-1 order-sm-1 order-md-2 img-container" data-aos="fade-left" data-aos-duration="2000">
                        <img src={kurtholaihall} alt="" />
                    </div>

                </div>
                
            </div>

        </div>
    </div>
  )
}