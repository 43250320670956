import React from 'react'
import './Faci.css';


import image2 from '../../images/rr-poomalai.png'
import mavilai from '../../images/mavilai dining.png'
import image5 from '../../images/kurutholai hall.png'
import image6 from '../../images/rr-car.png'
import image7 from '../../images/Luxury AC Rooms.png'
import image8 from '../../images/temple.png'
import image3 from '../../images/dining.png'
import image4 from '../../images/chair.png'
import image9 from '../../images/AC.png'
import image10 from '../../images/Spacius Parking.png'
import image11 from '../../images/car.png'
import image12 from '../../images/room.png'
import image13 from '../../images/Furnished Rooms.png'
import image14 from '../../images/Open Space.png'


export const Facilities = () => {
  return (
   <div id="main-content">
    
      <div className="container-fluid main-container" >
        <div className="heading-container mt-4 sm-mt-0" data-aos="fade-up" data-aos-duration="2000">
            <h1 className="heading-name">Our Facilities</h1>
            
        </div>
      </div>

      <div className="container-fluid main-container">
        <div className="heading-container">
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <img src={image2} alt="" className='img-fluid '/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <div className="row gap-2 facilities-contain">
                        <h1 className='poomalai'>Poomalai Marriage Hall </h1>
                        <p className='poomalaipara'>Celebrate your dream wedding at our expansive Poomalai Marriage Hall. This spacious venue, covering an area of 84*66 sq ft, comfortably accommodates a large guest list, ensuring a memorable ceremony.   
                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center gap-2 facilities-icons">
                                <img src={image3} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 members">1000+ Members</p>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-2">
                                <img src={image4} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 chair">700+ Chair</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>

      <div className="container-fluid main-container mt-3">
        <div className="heading-container">
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <img src={image5} alt="" className='img-fluid'/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"data-aos="fade-up" data-aos-duration="2000">
                    <div className="row gap-2 facilities-contain">
                        <h1 className='poomalai'>Kurutholai Buffet Hall  </h1>
                        <p className='poomalaipara'>Gather your loved ones for a vibrant post-ceremony celebration in our dedicated Kurutholai Buffet Hall. With dimensions of 36*78 sq ft, this spacious hall provides comfortable seating and a perfect atmosphere for joyful festivities.   
                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center gap-2 facilities-icons">
                                <img src={image9} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 members">AC Hall</p>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-2">
                                <img src={image3} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 chair">100+ Members</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
      <div className="container-fluid main-container mt-3">
        <div className="heading-container">
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <img src={mavilai} alt="" className='img-fluid'/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"data-aos="fade-up" data-aos-duration="2000">
                    <div className="row gap-2 facilities-contain">
                        <h1 className='poomalai'>Maavilai Dining Hall  </h1>
                        <p className='poomalaipara'>Celebrate your special day at our magical Maavilai Marriage Hall. With 36*78 sq ft of space, this traditional venue accommodates up to 150 guests, ensuring a memorable celebration.   
                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center gap-2 facilities-icons">
                                <img src={image9} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 members">AC Hall</p>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-2">
                                <img src={image3} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 chair">150+ Members</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
      <div className="container-fluid main-container mt-3">
        <div className="heading-container">
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <img src={image6} alt="" className='img-fluid '/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <div className="row gap-2 facilities-contain">
                        <h1 className='poomalai'>Car Parking  </h1>
                        <p className='poomalaipara'>We understand the importance of convenience for your guests. Our extensive parking lot ensures a stress-free arrival for everyone attending your special event. 
                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center gap-2 facilities-icons">
                                <img src={image10} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 members">Spacius Parking</p>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-2">
                                <img src={image11} alt="" className='  memberimage'/>
                                <p class="mb-0 ml-5 chair">120+ Cars</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
      <div className="container-fluid main-container mt-3">
        <div className="heading-container">
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <img src={image7} alt="" className='img-fluid'/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fadet-up" data-aos-duration="2000">
                    <div className="row gap-2 facilities-contain">
                        <h1 className='poomalai'>Luxury A/C Rooms </h1>
                        <p className='poomalaipara'>Relax and unwind in our luxurious air-conditioned rooms. With 14 rooms available, including 2 special rooms for the bride and groom, you and your wedding party can enjoy a comfortable and convenient stay. 
                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center gap-2 facilities-icons">
                                <img src={image12} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 members">14+ Rooms</p>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-2">
                                <img src={image13} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 chair">Furnished Rooms</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
      <div className="container-fluid main-container mt-3">
        <div className="heading-container">
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <img src={image8} alt="" className='img-fluid'/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                    <div className="row gap-2 facilities-contain">
                        <h1 className='poomalai'>Vinayagar Temple</h1>
                        <p className='poomalaipara'>Seek blessings for your joyous occasion at our Vinayagar Temple. This sacred space allows you to begin your celebration with a touch of spiritual significance.
                        </p>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center gap-2 facilities-icons">
                                <img src={image14} alt="" className=' memberimage'/>
                                <p class="mb-0 ml-5 members">Open Space </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
   
   </div>
  )
}