import 'bootstrap/dist/css/bootstrap.min.css';
// import Landing from './Component/Landing/Landing';
import VideoPlayer from './Component/ab/Ab';
// import Header from './Component/Header/Header';
import Footer from './Component/Footer/Fotter';
// import CircleFollow, { UseFollowPointer } from './Component/Circle/Use-follow-pointer';
import About from './Component/About/About';
// import Gallery from './Component/Gallery/Gallery';
import SideNavbar from './Component/Menu/Menu';
// import { Menu } from './Component/Menu/Menu';
// import { Menu } from './Component/Menu/Menu';
// import { Facilities } from './Component/Facilities/Faci';
// import Navbar from './Component/Nav/Nav';
// import Nav from './Component/Nav/Nav';
// import { Example } from './Component/Motione/Example';
// import Mainee from './Component/Circle/Main_rounde';
import { Route, Routes } from 'react-router-dom';
import Gallery from './Component/Gallery/Gallery';
import { Facilities } from './Component/Facilities/Faci';
import Whats from './Component/Whatsapp/Whats';
// import { Home } from './Component/Home/Home';





function App() {
  return (
    <div className="App">
      <SideNavbar/>
      <Routes>
        
        <Route path='/' element={<VideoPlayer/>}/>
        <Route path='/About' element={<About/>}/>
        <Route path='/Gallery' element={<Gallery/>}/>
        <Route path='/Facilities' element={<Facilities/>}/>
      </Routes> 
      <Whats/>
      
      
      <Footer/>
    </div>
  );
}

export default App;
